import BigNumber from 'bignumber.js'
import { TransactionType, defaultChainId } from '../config/constants'
import { updateTransaction } from '../state/transactions/actions'
import { getWBNBAddress } from './addressHelpers'
import { customNotify } from './notify'
import { BLOCK_GAS_LIMIT } from './chunkArray'

const backendApi = process.env.REACT_APP_BACKEND_API
const nestApi = process.env.REACT_APP_BACKEND_DA || 'https://dev.da.lynex.fi'

const DEXSCREENER_ENDPOINT = 'https://api.dexscreener.com/latest/dex/tokens/'

const getDexScreenerPrice = async (address) => {
  try {
    const queryUrl = DEXSCREENER_ENDPOINT + address
    const response = await fetch(`${queryUrl}`, {
      method: 'get',
    })
    const res = await response.json()
    if (res.pairs && res.pairs.length > 0) {
      const found = res.pairs.find((pair) => pair.baseToken.address.toLowerCase() === address)
      if (found) {
        return Number(found.priceUsd)
      }
    }
    return 0
  } catch (error) {
    console.error(`[asset] dexscreener api error: ${error}`)
    return 0
  }
}

const getBaseAssets = async () => {
  try {
    const response = await fetch(`${nestApi}/tracking/assets`, {
      method: 'get',
    })
    const baseAssetsCall = await response.json()
    let baseAssets = baseAssetsCall

    const wbnbPrice = baseAssets.find((asset) => asset.address.toLowerCase() === getWBNBAddress().toLowerCase())?.price

    const nativeBNB = {
      address: 'ETH',
      name: 'ETH',
      symbol: 'ETH',
      decimals: 18,
      logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
      price: wbnbPrice,
    }
    baseAssets.unshift(nativeBNB)
    return baseAssets.map((item) => {
      return {
        ...item,
        balance: new BigNumber(0),
        chainId: defaultChainId,
      }
    })
  } catch (ex) {
    console.error('get baseAssets had error', ex)
    return null
  }
}

const DA_API = process.env.DA_API || 'https://dev.da.lynex.fi'

const getIdentity = async (address) => {
  try {
    const response = await fetch(`${DA_API}/webhook/thx/${address}`, {
      method: 'get',
    })
    const res = await response.json()
    return res
  } catch (ex) {
    console.error('get identity had error', ex)
    return null
  }
}

const getCircSupply = async () => {
  try {
    const response = await fetch(`${backendApi}/supply`, {
      method: 'get',
    })
    const supplyCall = await response.json()

    return supplyCall.data
  } catch (ex) {
    console.error('Supply fetched had error', ex)
    return null
  }
}

const getFusions = async () => {
  try {
    const response = await fetch(`${nestApi}/tracking/pools`, {
      method: 'get',
    })
    const pairsCall = await response.json()

    return pairsCall
  } catch (ex) {
    console.error('v3 Pairs fetched had error', ex)
    return []
  }
}

const getAirdropClaim = async (address, type) => {
  try {
    const response = await fetch(`${backendApi}/airdrop/${type}/${address?.toLowerCase()}`, {
      method: 'get',
    })
    const aidropClaimCall = await response.json()

    return aidropClaimCall
  } catch (ex) {
    console.error('get getAirdropClaim had error', ex)
    return null
  }
}

const getMetamaskSwaps = async (address) => {
  try {
    const response = await fetch(`https://data.mongodb-api.com/app/data-namgi/endpoint/swaps?address=${address?.toLowerCase()}`, {
      method: 'get',
    })
    const claim = await fetch(`https://data.mongodb-api.com/app/data-namgi/endpoint/claims?address=${address?.toLowerCase()}&op=check`, {
      method: 'get',
    })
    const aidropClaimCall = await response.json()
    const claimed = await claim.json()

    return { amount: aidropClaimCall, claimed: claimed.claimed === 'true', elegible: claimed.result === 'true' }
  } catch (ex) {
    console.error('get getAirdropClaim had error', ex)
    return 0
  }
}
const submitFoxyClaim = async (address) => {
  try {
    const response = await fetch(`https://data.mongodb-api.com/app/data-namgi/endpoint/claims?address=${address?.toLowerCase()}&op=claim`, {
      method: 'get',
    })
    const aidropClaimCall = await response.json()

    return aidropClaimCall
  } catch (ex) {
    console.error('get getAirdropClaim had error', ex)
    return null
  }
}

const getAirdropClaimKey = async (address) => {
  try {
    const response = await fetch(`${backendApi}/airdropKey/${address?.toLowerCase()}`, {
      method: 'get',
    })
    const aidropClaimKeyCall = await response.json()

    return aidropClaimKeyCall
  } catch (ex) {
    console.error('get getAirdropClaimKey had error', ex)
    return null
  }
}

const getFloorPrice = async () => {
  try {
    const response = await fetch(`https://api.opensea.io/api/v1/collection/thenian/stats`, {
      method: 'get',
    })
    const res = await response.json()

    return res.stats
  } catch (ex) {
    console.error('opensea api fetch had error', ex)
    return []
  }
}

const sendContract = async (dispatch, key, uuid, contract, method, params, account, msgValue = '0') => {
  let hash
  dispatch(
    updateTransaction({
      key,
      uuid,
      status: TransactionType.WAITING,
    }),
  )
  let gasLimit
  if (method === 'claimFees' || method === 'claimBribes' || method === 'getReward') {
    const gasRequired = await contract.methods[method](...params).estimateGas({
      from: account,
      value: msgValue,
    })
    gasLimit = parseInt(gasRequired * 1.8, 0)
    if (BLOCK_GAS_LIMIT < gasLimit) {
      gasLimit = parseInt(gasRequired * 1.4, 0)
      if (BLOCK_GAS_LIMIT < gasLimit) {
        customNotify('Transaction exceeds block limit, try smaller batches', 'error')
        throw 'Exceeds block limit'
      }
    }
  }
  return new Promise((resolve, reject) => {
    contract.methods[method](...params)
      .send({
        from: account,
        value: msgValue,
        gasLimit,
        /// @notice this forces the web3 provider to compute optimal values
        maxPriorityFeePerGas: null,
        maxFeePerGas: null,
        // maxFeePerGas: gasPrices.instant,
      })
      .on('transactionHash', (tx) => {
        hash = tx
        dispatch(
          updateTransaction({
            key,
            uuid,
            status: TransactionType.PENDING,
            hash,
          }),
        )
      })
      .then((res) => {
        dispatch(
          updateTransaction({
            key,
            uuid,
            status: TransactionType.SUCCESS,
            hash,
          }),
        )
        customNotify('Transaction Successful!', 'success', hash)
        resolve(res)
      })
      .catch((err) => {
        dispatch(
          updateTransaction({
            key,
            uuid,
            status: TransactionType.FAILED,
            hash,
          }),
        )
        customNotify(err.message, 'error')
        reject(err)
      })
  })
}

const sendV3Contract = async (dispatch, key, uuid, web3, from, to, data, msgValue = '0') => {
  let hash
  dispatch(
    updateTransaction({
      key,
      uuid,
      status: TransactionType.WAITING,
    }),
  )
  return new Promise((resolve, reject) => {
    web3.eth
      .sendTransaction({
        from,
        to,
        data,
        value: msgValue,
        /// @notice this forces the web3 provider to compute optimal values
        maxPriorityFeePerGas: null,
        maxFeePerGas: null,
        // maxFeePerGas: gasPrices.instant,
      })
      .on('transactionHash', (tx) => {
        hash = tx
        dispatch(
          updateTransaction({
            key,
            uuid,
            status: TransactionType.PENDING,
            hash,
          }),
        )
      })
      .then((res) => {
        dispatch(
          updateTransaction({
            key,
            uuid,
            status: TransactionType.SUCCESS,
            hash,
          }),
        )
        customNotify('Transaction Successful!', 'success', hash)
        resolve(res)
      })
      .catch((err) => {
        dispatch(
          updateTransaction({
            key,
            uuid,
            status: TransactionType.FAILED,
            hash,
          }),
        )
        customNotify(err.message, 'error')
        reject(err)
      })
  })
}

const getAllowance = async (contract, target, account) => {
  try {
    return await contract.methods.allowance(account, target).call()
  } catch (ex) {
    console.error(ex)
    return 0
  }
}

export {
  getDexScreenerPrice,
  submitFoxyClaim,
  getMetamaskSwaps,
  getBaseAssets,
  getAirdropClaim,
  getAirdropClaimKey,
  getCircSupply,
  getFusions,
  sendContract,
  sendV3Contract,
  getAllowance,
  getFloorPrice,
  getIdentity,
}
